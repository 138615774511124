const translations: Record<string, Record<string, string>> = {
    "en": require('./tranlations/translation_en.json'),
    "ar": require('./tranlations/translation_ar.json')
};

export const translateLanguage = (key: string) => {
    let langauge = "ar"
    if (localStorage.getItem('language')) {
        langauge = localStorage.getItem('language') || "en"
    }

    let returnText: string = translations[langauge][key]
    return returnText
}

export default translations;
