import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
// import { arSD } from '@mui/x-data-grid/locales';
import { arSD } from '@mui/material/locale'

// Create a theme instance.
const theme = createTheme(
  {
    direction: 'rtl',

    typography: {
      button: {
        textTransform: 'none'
      }
    },
    palette: {
      primary: {
        main: '#0098e5',
      },
      secondary: {
        main: '#19857b',
      },
      error: {
        main: red.A400,
      },
    },
  },
  arSD
);

export default theme;
